import { Component, OnInit } from "@angular/core";
import templateString from './populatedReports.component.html'
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { PopulatedReportDetailsComponent } from "../populatedReportDetails";
import { Candidate } from "site/app/models/candidate.model";
import { PopulatedReportBudgetsComponent } from "../populatedReportBudgets";
import { EnvironmentService } from "site/app/environment.service";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";

@Component({ 
	template: templateString
})
export class PopulatedReportsComponent implements OnInit {
	public isLoading: boolean;
	public reports: any;
	public reportId: any;
	public selectedReport: any;
	public report: any;
	public candidatesForSelection: any;
	public candidatesForReport: any;
	public beginningsOfMonths: any;
	public occupationsAndTotalsForEntirePeriod: any;
	public budgetsAndTotalsPerEntirePeriod: any;
	public periodType: any;
	public year: any;
	public years: any;
	public startDate: any;
	public endDate: any;
	public candidateId: any;
	public showReportContainer: any = false;
	public user: any;
	public horizontalScrollReceiver1: any;
	public verticalScrollReceiver1: any;
	public candidateTypes: any;
	public candidateTypeIds: {} = {};
	public checkAllCandidateTypeIds: any = true;
	public showMonths: any;

	constructor(
		private http: HttpClient,
		private bsModalService: BsModalService,
		private environmentService: EnvironmentService,
		private datePipe: DatePipe
	) { }
	
	changePeriod() {
		this.http.get<any>("candidates/index_for_reports", { params: { period_type: this.periodType, year: this.year } }).subscribe(x => {
			this.candidatesForSelection = x.map(function(y) { return new Candidate(y); });
        });
	}

	showReport() {
		var self = this;

		this.showReportContainer = false;
		this.isLoading = true;
		this.http.get("reports/show_report",
		{ 
			params: {
				report_id: this.reportId, 
				period_type: this.periodType,
				year: this.year,
				start_date: this.startDate,
				end_date: this.endDate,
				"candidate_type_ids[]": this.candidateTypes.map(function(x) { return self.candidateTypeIds[x.id] ? x.id : null; }).filter(function(x) { return x; }),
				candidate_id: this.candidateId	
			}
		}).subscribe(result => {
			this.report = JSON.parse(result["report"]);
			this.candidatesForReport = JSON.parse(result["candidates"]);
			this.beginningsOfMonths = result["beginnings_of_months"];
			this.occupationsAndTotalsForEntirePeriod = result["occupations_and_totals_for_entire_period"];
			this.budgetsAndTotalsPerEntirePeriod = result["budgets_and_totals_per_entire_period"];

			this.candidatesForReport.forEach(function(candidate) {
				candidate.lastnameWithInfix = (new Candidate(candidate)).lastnameWithInfix();
			});

			this.showReportContainer = true;
			this.isLoading = false;
		});
	}

	showDetails(candidate, beginningOfMonth) {
		this.bsModalService.show(PopulatedReportDetailsComponent, {
            initialState: {
				report: this.report,
				candidate: candidate,
				beginningOfMonth: beginningOfMonth,
				occupationsAndTotals: this.occupationsAndTotalsForEntirePeriod[candidate.id][beginningOfMonth]
            },
            class: 'xlModal'
        });
	}

	showBudgets(candidate) {
		this.bsModalService.show(PopulatedReportBudgetsComponent, {
            initialState: {
				report: this.report,
				candidate: candidate,
				year: this.year,
				budgetsAndTotals: this.budgetsAndTotalsPerEntirePeriod[candidate.id]
            },
            class: 'xlModal'
        });
	}

	checkUncheckAllCandidateTypeIds() {
        var self = this;
		this.candidateTypes.forEach(function(candidateType) {
			self.candidateTypeIds[candidateType.id] = self.checkAllCandidateTypeIds;
		});
	}	

	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
	}

	exportToExcel() {
		var self = this;

			var printContents = '' +
'				<html>' +
'					<head>' +
'						<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css">' +
'						' +
'						<style>' +
'							body {' +
'						    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;' +
'						    background-color: #ffffff;' +
'								font-size: 13px;' +
'							}' +
'							table {' +
'								margin: 0;' +
'								border-spacing: 0;' +
'								border-collapse: collapse;' +
'							}' +
'							th, td {' +
'								font-size: 13px;' +
'								margin: 0;' +
'								border-spacing: 0;' +
'								padding: 3px;' +
'							}' +
'							td {' +
'								border: 1px solid #eee;' +
'							}' +
'							td.with-nested-table {' +
'								border-top: 0;' +
'								border-right: 0;' +
'								border-bottom: 0;' +
'							}' +
'							td.first-with-nested-table {' +
'								border-top: 1px solid #eee;' +
'							}' +
'							.nested-table td {' +
'								border-top: 0;' +
'								border-left: 0;' +
'							}						' +
'						</style>' +
'					</head>' +
'					<body>' +

// <h5>Rapport '{{report.name}}'</h5>

' 						<table>' +
'							<thead>' +
'								<tr>' +
'									<th>Naam</th>' +
(self.user.customer.id == 26 ? '									<th>Artstype</th>' : '') +
'									<th>Type</th>' +
									self.beginningsOfMonths.map(function(beginningOfMonth) { return '' +
'										<th>' + self.datePipe.transform(beginningOfMonth, 'MMMM yyyy') + '</th>' +
'										<th></th>';
									}).join('') +
'									<th>Totaal</th>' +
'									<th></th>' +
'								</tr>' +
'							</thead>' +
'							<tbody>' +
								self.candidatesForReport.map(function(candidate) { return '' +
								'<tr></tr>' +
								self.report.report_parts.map(function(reportPart, index) { return '' +
'								<tr>' +
'									<td>' + (index == 0 ? (new Candidate(candidate).lastnameWithInfix()) : '') + '</td>' +
(self.user.customer.id == 26 ? '									<td>' + candidate.candidate_type_name + '</td>' : '') +
'									<td>' + reportPart.name + '</td>' +
									self.beginningsOfMonths.map(function(beginningOfMonth) { return '' +
'									<td>' + self.occupationsAndTotalsForEntirePeriod[candidate.id][beginningOfMonth]['totals'][reportPart.id][0].toString().replace('.', ',') + '</td>' +
'									<td>' + (self.occupationsAndTotalsForEntirePeriod[candidate.id][beginningOfMonth]['totals'][reportPart.id][1] ? self.occupationsAndTotalsForEntirePeriod[candidate.id][beginningOfMonth]['totals'][reportPart.id][1].toString().replace('.', ',') : '') + '</td>';
									}).join('') +
'									<td>' + self.budgetsAndTotalsPerEntirePeriod[candidate.id][reportPart.id]['totals'][0].toString().replace('.', ',') + '</td>' +
'									<td>' + (self.budgetsAndTotalsPerEntirePeriod[candidate.id][reportPart.id]['totals'][1] ? self.budgetsAndTotalsPerEntirePeriod[candidate.id][reportPart.id]['totals'][1].toString().replace('.', ',') : '') + '</td>' +
'								</tr>';
								}).join('');
								}).join('') +
'							</tbody>' +
'						</table>' +
'					</body>' +
'				</html>';

		var printWindow=window.open('','','width=800,height=600,top=0,left=0,scrollbars=1,resizable=1');

		// printWindow is null when popup is blocked by browser's popup blocker
		if (printWindow != null) {
			printWindow.document.write(printContents);
			printWindow.document.close();
			printWindow.focus();
		}
	}

	setSelectedReport() {
		var self = this;
		this.selectedReport = this.reportId ? this.reports.filter(function(x) { return x.id == self.reportId; })[0] : null;

		if (!this.user.isAdmin && this.selectedReport && !this.selectedReport.is_visible_to_others) {
			this.candidateId = this.user.candidateId;
		}
	}

	ngOnInit(): void {
		var self = this;

		this.user = this.environmentService.getUser();

		this.showMonths = this.user.customer.id != 21;

		this.http.get("candidate_types").subscribe(candidateTypes => {
			this.candidateTypes = candidateTypes;

			this.candidateTypes.forEach(function(y) { 
				self.candidateTypeIds[y.id] = true;
			});
		});

		if (this.user.candidateId) {
			this.candidateId = this.user.candidateId;
		} else {
			this.candidateId = 0;
		}

		this.http.get("reports").subscribe(x => {
			this.reports = x;
			this.reportId = this.reports.length > 0 ? this.reports[0].id : null;
			this.setSelectedReport();
        });

		this.periodType = "by_year";
		this.years = [2020, 2021, 2022, 2023, 2024, 2025];
		this.year = 2024;
		this.changePeriod();		
	}	

	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		this.verticalScrollReceiver1 = document.querySelectorAll('.myVerticalScrollReceiver')[0];
	}
}
