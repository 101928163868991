import { Component, OnInit } from '@angular/core';
import templateString from './taskAccumulationPeriods.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskAccumulationPeriodFormComponent } from '../taskAccumulationPeriodForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskAccumulationPeriodsComponent implements OnInit {
    public destroyed$: any;
    public isLoading: boolean;
    public selectedSchedulePeriod: any;
    public taskAccumulationPeriods: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

	refresh() {
        this.http.get("task_accumulation_periods", { params: { schedule_period_id: this.selectedSchedulePeriod.id, show_all: "true" } }).subscribe(x => {
            this.taskAccumulationPeriods = x;
			this.isLoading = false;
		});
    }

    create() {
        var bsModalRef = this.bsModalService.show(TaskAccumulationPeriodFormComponent, {
            initialState: {
                taskAccumulationPeriodId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id,
                taskAccumulationPeriods: this.taskAccumulationPeriods
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(taskAccumulationPeriodId) {
        var bsModalRef = this.bsModalService.show(TaskAccumulationPeriodFormComponent, {
            initialState: {
                taskAccumulationPeriodId: taskAccumulationPeriodId,
                schedulePeriodId: this.selectedSchedulePeriod.id,
                taskAccumulationPeriods: this.taskAccumulationPeriods
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(taskAccumulationPeriodId) {
		if (window.confirm('Weet je het zeker?')) {
            this.http.delete("task_accumulation_periods/" + taskAccumulationPeriodId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.error.error);
			});
		}
	};

	initialize() {
		this.isLoading = true;
		
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
